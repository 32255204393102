import React from 'react';
import { navigate } from 'gatsby';
import Layout from '../components/layout';
import { getPathFromOriginalPath } from 'root/_ultility';
import * as qs from 'query-string';

interface IPropertyRedirectPageProps {
    pageContext: any;
}

interface IPropertyRedirectPageState {
    [key: string]: any;
}

class PropertyRedirectPage extends React.Component<IPropertyRedirectPageProps, IPropertyRedirectPageState>  {
    constructor(props: IPropertyRedirectPageProps) {
        super(props);
    }

    componentDidMount() {
        const parsed = qs.parse(location.search);

        if (typeof parsed['id'] !== 'undefined') {
            const path = getPathFromOriginalPath(`/property/${parsed['id']}/`);
            navigate(path);
        }
    }

    render() {
        const { pageContext } = this.props;

        return (
            <Layout config={pageContext.layout} isHomePage={pageContext.isHomePage}>
                <div style={{ textAlign: 'center', height: '500px' }}><img src='/images/loading.gif' className='loading-image' alt='loading-image' /></div>
            </Layout >
        );
    }
}

export default PropertyRedirectPage;
